<template>
  <div class="op_module">
    <div class="list_tit">
      <h2>运营系统人员列表</h2>
      <div class="btn_group">
        <el-button type="primary" size="mini" icon="el-icon-plus" @click="newOeidt('register_account',{})">新增</el-button>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="list_cnt">
      <el-table
        :data="personList"
        stripe
        size="small"
        style="width: 100%">
        <el-table-column
          prop="user_name"
          label="用户名">
        </el-table-column>
        <el-table-column
          prop="email"
          label="邮箱">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号">
        </el-table-column>
        <el-table-column
          prop="real_name"
          label="真实姓名">
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="账号创建时间">
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="110">
          <template slot-scope="scope" >
            <el-button type="text" @click="newOeidt('update',scope.row)" style="margin-right:12px;" disabled>编辑</el-button>
            <!-- <el-button type="text" @click="resetPwd(scope.row.email)" style="margin: 0px 12px;">重置密码</el-button> -->
            <!-- 删除弹框 -->
            <el-popover
              :ref="`popover-${scope.$index}`"
              placement="left"
              v-model="visible">
              <p style="margin: 12px 0px;">确定要注销<span style="color:red;">{{scope.row.real_name}}</span>的账号吗？</p>
              <div style="text-align: right;">
                <el-button size="mini" type="text" @click="popoverClose(scope)">取消</el-button>
                <el-button type="primary" size="mini" @click="yesDel(scope)">确定</el-button>
              </div>
              <el-button slot="reference" type="text">注销</el-button>
            </el-popover>
            <!-- <i class="el-icon-edit" @click="theAssets('mod_assets',scope.row)" style="margin:0 10px"></i> -->
            <!-- <i class="el-icon-delete" @click="areUsure(scope.row)"></i> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog 
      :title="dialogType =='register_account'?'新增员工账号':'编辑员工账号'" 
      :visible.sync="opUvisible"
      :close-on-click-modal="false"
      width='440px'>
      <el-form :model="staffForm" :rules="rules" ref="staffForm" label-width="85px">
          <el-form-item label="用户名:" prop="user_name">
            <el-input v-model="staffForm.user_name" placeholder="必填"></el-input>
          </el-form-item>
          <el-form-item label="邮箱:" prop="email">
            <el-input v-model="staffForm.email" placeholder="必填" :disabled="dialogType == 'update'"></el-input>
          </el-form-item>
          <el-form-item label="密码:" prop="password">
            <el-input v-model="staffForm.password" type="password" placeholder="必填" :disabled="dialogType == 'update'"></el-input>
          </el-form-item>
          <el-form-item label="确认密码:" prop="confirm_password">
            <el-input v-model="staffForm.confirm_password" type="password" placeholder="必填" :disabled="dialogType == 'update'"></el-input>
          </el-form-item>
          <!-- <el-form-item label="手机号:" prop="phone">
            <el-input v-model="staffForm.phone" placeholder="必填"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="真实姓名:" prop="real_name">
            <el-input v-model="staffForm.real_name" placeholder="必填"></el-input>
          </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="opUvisible = false">取 消</el-button>
        <el-button type="primary" @click="submitInfo">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data(){
    const equalToPassword = (rule, value, callback) => {
      // console.log(this.staffForm)
      if (this.staffForm.password !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      delVisible:false,//删除确认框
      opUvisible:false,//员工信息表单显隐
      staffForm:{},//员工信息框
      dialogType:'create',//员工信息表单类型
      personList:[],
      rules:{//表单验证
        'password':[
          {required: true,message: '请输入密码',trigger: 'blur'}
        ],
        'user_name':[{required: true,message: "请输入员工用户名", trigger: "blur"},
          {
          validator: function (rule, value, callback) {
            if (/[\u4e00-\u9fa5]/.test(value) == false) {
              callback(new Error("请输入正确的名称"));
            } else {
              callback();
            }
          }
          }
        ],
        'email':[
          {required: true,type: 'email',message: '请输入正确的邮箱地址',trigger: ['blur', 'change']}
        ],
        'confirm_password':[
          {required: true,message: "请再次输入密码", trigger: "blur"},
          {validator: equalToPassword,required: true,trigger: "blur"}
        ]

      }
    }
  },
  methods:{
    getList(){//获取人员列表
      this.$http({
        method:'post',
        url: '/user/read_account',
        data:{account_type:'运营'}
      }).then(res => {
        // console.log(res)
        if(res.data.code == 200){
          this.personList = res.data.result.user_list;
          // console.log(this.personList)
        }
      }).catch(error =>{
        console.log(error)
      });
    },
    newOeidt(type,obj){//员工信息新建编辑分流
      if(type == 'update'){
        let {email,phone,real_name,user_name} = obj
        this.staffForm = {email,phone,real_name,user_name}
      }else{
        this.staffForm = obj;
      }
      this.dialogType = type;
      this.opUvisible = true;
    },
    submitInfo(){//提交员工信息
      // console.log(this.staffForm)
      this.$refs['staffForm'].validate((valid) => {
        if (valid) {
          this.staffForm.account_type = '运营'
          this.$myutils.addAedit(this.staffForm,this.dialogType,(result)=>{
            if(result.code == 200){
              this.getList();
              this.$message.success(result.msg);
              this.opUvisible = false;
            }else{
              this.$message.error(result.msg);
            }
          });
        //   this.$http({
        //     method:'post',
        //     url: '/user/'+this.dialogType,
        //     data:this.staffForm
        //   }).then(res => {
        //     // console.log(res)
        //     if(res.data.code == 200){
        //       this.getList();
        //       this.$message.success('操作成功');
        //       this.opUvisible = false;
        //       // this.personList = res.data.data;
        //     }
        //   }).catch(error =>{
        //     console.log(error)
        //   });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    popoverClose(scope){//关闭删除确认框
      this.$refs[`popover-${scope.$index}`].doClose()
    },
    yesDel(scope){//确认注销
    // console.log({'email':scope.row.email})
      this.$myutils.logOff({account_type:'运营',u_id:scope.row.u_id},(result)=>{
        if(result.code == 200){
          this.getList();
          this.$message.success(result.msg);
          this.popoverClose(scope)
        }else{
          this.$message.error(result.msg);
        }
      });
    },
    resetPwd(e){//重置密码
      this.$http({
        method:'post',
        url: '/sec/newpwd',
        data:{'email':e}
      }).then(res => {
        // console.log(res)
        if(res.data.code==200){
          this.$message.success('新密码已发送至该员工邮箱！');
        }else{
          this.$message.error('重置失败！');
        }
      }).catch(error =>{
        console.log(error)
      });
    }
  },
  created(){
    this.getList();
    // this.c_name = JSON.parse(localStorage.getItem("cInfo")).user_name;
  },
}
</script>

<style lang="scss" scoped>
  .op_module ::v-deep{
    // display: flex;
    height: 100%;
    padding: 40px;
    background-color: #fff;
    // border-radius: 28px;
    box-shadow: 0px 0px 15px rgba(174, 187, 216, 0.5);
    .list_cnt{
      .el-table{
        height: calc(100vh - 56px - 80px - 30px - 49px );
        .el-table__header{
          text-align: left;
          .cell{
            font-weight: 900;
            font-size: 14px;
          }
        }
        .el-table__body-wrapper{
          height: calc(100% - 40px);
          overflow-y: auto;
          .el-table__row{
            .cell{
              // padding: 8px 10px;
            }
          }
          .el-table__row--striped{
            background-color: $light-base;
            border-radius: 12px;
          }
        }
      }
    }
    .list_tit{
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2{
        font-size: 18px;
        font-weight: 900;
      }
      // margin-bottom: 28px;
    }
  }
</style>
